:global(.p-dialog) {
  max-height: 100%;
  width: 100vw;
  height: 100vh;
  background-color: var(--White);
}

:global(.p-dialog-header) {
  font-family: Poppins-Regular, sans-serif;
  font-size: 20px;
  line-height: 28px;
  padding: 10px 20px;
  color: var(--Grey-800);
  border-bottom: 1px solid var(--Grey-200);
}

:global(.p-dialog-content) {
  border-radius: 0;
  overflow-x: hidden;
}

.primeWrapper {
  padding: 26px 20px;
  overflow-y: auto;
}

:global(.p-dialog-footer) {
  padding: 0;
}

:global(.modal-footer-wrapper) {
  padding: 10px 20px;
  border-top: 1px solid var(--Grey-200);
  border-bottom: 1px solid var(--Grey-200);
}

:global(.p-dialog-header-close:hover) {
  background: none;
}

@media screen and (min-width: 576px) {
  :global(.p-dialog) {
    max-width: 100vw;
    height: initial;
    max-height: 90%;
  }

  /* Addded 4px padding so fields that at the bottom of modal has 
     enough space to have focus box shadow */
  .primeWrapper {
    padding: 0 32px 4px;
    overflow-y: initial;
  }

  :global(.p-dialog-header) {
    padding: 32px 32px 16px;
    border: 0;
    margin: 0;
  }

  :global(.p-dialog-footer) {
    padding: 28px 32px 32px;
  }

  :global(.modal-footer-wrapper) {
    border: 0;
    padding: 0;
  }
}
