.additionalText {
  color: var(--Grey-500);
}

.camerasDropdown {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 8px;

  button {
    width: auto;
  }
}

.dropdownBtn {
  width: 256px;
}

@media screen and (min-width: 1024px) {
  .camerasDropdown {
    display: flex;
    align-items: center;
    width: auto;
    margin-left: auto;
  }
}
