.linkButton {
  background: none;
  border: none;
  padding: 0;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.actionButton {
  margin-right: 8px;
}

.inputHeader {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  font-weight: 600;
}

.editFormBlockWrapper,
.deviceSettingsWrapper {
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .editFormBlockWrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fit, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .deviceSettingsWrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fit, 1fr);
  }
}
