:global(.dropdownItem) {
  max-width: 376px;
}

.dropdownContainer {
  background: #fff;
  border: 1px solid #d7dbe3;
  box-sizing: border-box;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07), 0 3px 6px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  position: absolute;
  z-index: 99990 !important;
  transition: all 0.2s ease-in-out;
}

.dropdownInPopup {
  z-index: 1001;
}

.inner {
  overflow: auto;
}

.dropdown {
  width: 100%;
}

.inner::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.innerWithButtons {
  max-height: 160px;
  overflow: auto;
}

.dropdownTitle {
  font-family: Poppins-SemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdownBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border: 1px solid #d7dbe3;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  height: 40px;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background: var(--Grey-100);
}

.selectedDropdownItemName {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.text {
  font-family: Poppins-SemiBold, sans-serif;
  color: var(--Grey-900);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selectedDropdownItemText {
  font-family: Poppins-SemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--Grey-900);
  margin-left: 8px;
}

.selectOption {
  font-family: Poppins-SemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--Grey-600);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdownItemContainer {
  height: 40px;
  padding: 0 12px 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
}

.clearItem:not(.dropdownItemSelectedName) {
  color: var(--Grey-600);
}

.dropdownItemContainer:hover {
  background-color: var(--Grey-150);
}

.selectedItem {
  background: linear-gradient(
    270deg,
    var(--Grey-50) 0%,
    var(--Primary-50) 60.89%
  );
}

.dropdownItemName {
  font-family: Poppins-SemiBold, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--Grey-900);
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.dropdownItemDisabledName {
  color: var(--Grey-600);
}

.dropdownItemSelectedName {
  color: var(--Primary-700);
}

.dropdownFlag {
  margin-right: 8px;
  stroke: var(--Primary-700);
  width: 20px !important;
  height: 15px !important;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin: 0 12px;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin: 6px;
}

.headerBtn {
  font-family: Poppins-SemiBold, sans-serif;
  color: var(--Primary-700);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  cursor: pointer;
}

.focused:not(.selectedItem) {
  background-color: var(--Grey-150);
}

.dropdownBtn.inputValidationError,
.dropdownBtn.inputValidationError:not(:active):not(:focus):hover {
  border-color: var(--Error-400);
}

/* stylelint-disable-next-line selector-class-pattern */
.inputValidationError:not(.p-inputwrapper-focus) {
  border: 1px solid var(--Error-400);
}

.validationError {
  font-size: 12px;
  line-height: 20px;
  color: var(--Error-600);
  display: flex;
}
.validationError svg {
  margin: 3px 2px 0 0;
}

.validationError svg path {
  stroke: var(--Error-600);
}
