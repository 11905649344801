.tableContainer {
  width: 100%;

  --header-color-local: var(--Grey-700);
  --body-color-local: var(--Grey-800);
  --th-td-padding-x: 8px;
  --th-td-padding-y: 8px;
  --th-td-editable-padding-x: 8px;
  --th-td-editable-padding-y: 4px;
}

.tableControlsRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0;
  border-bottom: 1px solid var(--Grey-100);
}

.tableColumnsApplyButton {
  margin-right: 8px;
}

.tableNoDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--Grey-800);
  padding: 144px 0;
  max-width: 320px;
  text-align: center;
}

.modalNoItems {
  margin: auto;
  padding: 16px;
}

.noDataTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.noDataSubTitle {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.noDataReset {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.tableBottomCotrolsContainer {
  padding-top: 24px;
  display: flex;
  justify-content: center;
}

.tableCell {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  width: 100%;
  overflow: hidden;
}

:global(.tableCell-bold) {
  font-weight: bold;
}

:global(.alignRight).tableCell {
  justify-content: flex-end;
}

:global(.nodata).tableCell {
  color: var(--Grey-600);
}

.tableLink {
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  color: var(--body-color-local);
  background: none;
  border: none;
  text-align: left;
}

.linkHover:hover {
  cursor: pointer;
  color: var(--Primary-800);
}

:global(.truncateText[placeholder]),
.truncateText {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sortableHeader {
  display: inline-flex !important;
  width: 100%;
  align-items: center;
  color: var(--header-color-local);
}

.sortableHeaderIcon {
  margin-left: auto;
}

.sortableRowCol i {
  transform: rotate(90deg);
  color: var(--Primary-700);
}

.tableRowActions {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  vertical-align: middle;
}

.totalResult {
  display: inline-flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--header-color-local);
  padding: var(--th-td-padding-y) var(--th-td-padding-x);
  margin: var(--th-td-padding-y) var(--th-td-padding-x);
}

:global(
    .customDatatableContainer .p-datatable-reorderablerow-handle.pi.pi-bars
  ) {
  cursor: pointer;
}

:global(
    .customDatatableContainer.p-datatable
      .p-datatable-tbody
      .p-datatable-emptymessage
      > td
  ) {
  text-align: center;
  color: var(--Grey-400);
}

.tableActionsCol {
  --action-button-width: 24px;
  --action-button-margin: 8px;
}

.tableRowActionButton,
.tableRowActionButton:hover,
.tableRowActionButton:active,
.tableRowActionButton:focus {
  background: none;
  outline: none;
  border: none;
  box-shadow: none;
  margin: 0;
  line-height: 0;
  cursor: pointer;
}

/* calculate width based on N of action buttons */
.tableActionsCol_N_1 {
  width: calc(
    var(--action-button-width) + var(--th-td-padding-x) * 2
  ) !important;
}

.tableActionsCol_N_2 {
  width: calc(
    var(--action-button-width) * 2 + var(--action-button-margin) * 1 +
      var(--th-td-padding-x) * 2
  ) !important;
}

.tableActionsCol_N_3 {
  width: calc(
    var(--action-button-width) * 3 + var(--action-button-margin) * 2 +
      var(--th-td-padding-x) * 2
  ) !important;
}

.tableActionsCol_N_4 {
  width: calc(
    var(--action-button-width) * 4 + var(--action-button-margin) * 3 +
      var(--th-td-padding-x) * 2
  ) !important;
}

.tableActionsCol_N_5 {
  width: calc(
    var(--action-button-width) * 5 + var(--action-button-margin) * 4 +
      var(--th-td-padding-x) * 2
  ) !important;
}

.checkboxRow,
.childDetailsRow {
  display: flex;
  height: fit-content;
  justify-content: space-between;
}

.childDetailsRow svg {
  height: 40px;
  width: 20px;
  cursor: pointer;
}

.selectedChild {
  display: flex;
  align-self: center;
  font-weight: 600;
  color: var(--Grey-400);
}

/* #region primereact table overrides */

:global(.customDatatableContainer.p-datatable.p-component) {
  font-family: Poppins-Regular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

:global(.customDatatableContainer.p-datatable table) {
  border-collapse: collapse;
}

:global(table.customDatatable .p-datatable-thead > tr > th) {
  padding: var(--th-td-padding-y) var(--th-td-padding-x);
  background: var(--Grey-50);
  color: var(--header-color-local);
  font-weight: 600;
  border: 1px solid var(--Grey-100);
  border-width: 0 0 1px 0;
  border-collapse: collapse;
  outline: none;
  box-shadow: none;
  height: 48px;
  max-width: 146px;
  width: fit-content;
}

:global(
    table.customDatatable .p-datatable-thead > tr > th.p-sortable-column
  ):hover {
  background: var(--Grey-100);
}

.sortActive {
  background: var(--Primary-50) !important;
}

:global(table.customDatatable .subrow .cell) {
  width: 90%;
  height: 48px;
  display: flex;
  align-items: center;
}

:global(table.customDatatable .subrow .tableRowActionButton) {
  justify-self: right;
}

:global(.objectChangeLogRow) {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 5px;
}

:global(.objectChangeLogField) {
  color: var(--Grey-700);
  display: flex;
  height: 48%;
}

:global(.objectChangeLogOldValue),
:global(.objectChangeLogNewValue) {
  color: var(--Grey-800);
  display: flex;
  padding: 2px 5px;
  margin-bottom: 5px;
  height: 48%;
  width: fit-content;
}

:global(.objectChangeLogNewValue) {
  background-color: var(--Success-50);
}

:global(.objectChangeLogOldValue) {
  background-color: var(--Error-50);
  text-decoration: line-through;
}

:global(.objectChangeLogColorBox) {
  display: flex;
  padding: 0;
  align-self: center;
  margin-left: 10px;
  width: 15px;
  height: 15px;
}

:global(table.customDatatable .p-datatable-thead > tr > th.p-selection-column) {
  padding: 16px;
}

:global(table.customDatatable .p-datatable-tbody > tr) {
  background: var(--White);
  color: var(--body-color-local);
}

:global(table.customDatatable .p-datatable-tbody > tr.activeRow) {
  background-color: var(--Primary-50);
}

:global(table.customDatatable .p-datatable-tbody > tr > td.action-column),
:global(
    table.customDatatable .p-datatable-tbody > tr > td:not(.action-column)
  ) {
  padding: var(--th-td-padding-y) var(--th-td-padding-x);
  color: var(--body-color-local);
  outline: none;
  height: 48px;
  max-width: 146px;
  width: fit-content;
}

:global(table.customDatatable .p-datatable-tbody > tr > td.action-column),
:global(table.customDatatable .p-datatable-tbody > tr > td.p-selection-column) {
  overflow: initial;
}

:global(table.customDatatable .p-datatable-tbody > tr > td.editableCell) {
  padding: var(--th-td-editable-padding-y) var(--th-td-editable-padding-x);
}

:global(table.customDatatable .p-datatable-tbody > tr > td.action-column),
:global(table.customDatatable .p-datatable-thead > tr > th.p-selection-column),
:global(table.customDatatable .p-datatable-tbody > tr > td.p-selection-column) {
  padding: 0;
  width: 48px;
}

:global(
    table.customDatatable
      .p-datatable-thead
      > tr
      > th.p-selection-column
      > div.p-column-header-content
  ),
:global(
    table.customDatatable
      .p-datatable-tbody
      > tr
      > td.p-selection-column
      > div.p-checkbox
  ) {
  padding: var(--th-td-editable-padding-y) var(--th-td-editable-padding-x);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-right: 2px;
  box-shadow: 2px 0 0 0 #0000001f;
}

:global(
    table.customDatatable .p-datatable-tbody > tr > td div.action-button-wrapper
  ),
:global(.action-button-wrapper) {
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--th-td-padding-y) var(--th-td-padding-x);
  margin-left: 2px;
  box-shadow: -2px 0 0 0 #0000001f;
}

:global(
    table.customDatatable
      .p-datatable-thead
      > tr
      > th.p-selection-column
      span.p-column-title
  ),
:global(
    table.customDatatable
      .p-datatable-thead
      > tr
      > th.p-selection-column
      .p-column-resizer
  ) {
  display: none;
}

/* rows hover */
:global(
    .customDatatableContainer.p-datatable:not(
        .hideSelection
      ).p-datatable-hoverable-rows
      .p-datatable-tbody
      > tr:not(.p-highlight):hover
  ),
:global(
    .customDatatableContainer.p-datatable:not(
        .hideSelection
      ).p-datatable-hoverable-rows
      .p-datatable-tbody
      > tr:not(.p-highlight):hover
      td
  ) {
  background: var(--Primary-50);
  color: var(--body-color-local);
}

:global(
    .customDatatableContainer.p-datatable.hideSelection.p-datatable-hoverable-rows
      .p-datatable-tbody
      > tr:not(.p-highlight):hover
  ),
:global(
    .customDatatableContainer.p-datatable.hideSelection.p-datatable-hoverable-rows
      .p-datatable-tbody
      > tr:not(.p-highlight):hover
      td
  ) {
  background: var(--White);
}

:global(
    .customDatatableContainer.p-datatable.p-datatable-hoverable-rows
      .p-datatable-tbody
      > tr.p-selectable-row:not(.p-highlight):hover
  ),
:global(
    .customDatatableContainer.p-datatable.p-datatable-hoverable-rows
      .p-datatable-tbody
      > tr:not(.p-highlight):hover
      td.p-selection-column
      .p-checkbox
  ) {
  visibility: visible;
}

/* row selected */
:global(
    .customDatatableContainer.p-datatable
      .p-datatable-tbody
      > tr.p-highlight
      > td
  ) {
  background: var(--Primary-50);
  color: var(--body-color-local);
}

:global(
    .customDatatableContainer.p-datatable
      .p-datatable-tbody
      > tr.p-selectable-row.p-highlight
      td.p-selection-column
      .p-checkbox
  ) {
  visibility: visible;
}

/* sortable - hide default icon and display custom */
:global(
    table.customDatatable
      .p-datatable-thead
      > tr
      > th.p-sortable-column
      .p-column-title
  ) {
  width: 100%;
  display: inline-flex;
}

:global(
    table.customDatatable .p-datatable-thead > tr > th .p-column-title span
  ) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.hideHeader :global(table.customDatatable) {
  border-collapse: separate;
  border: 1px solid var(--Grey-200) !important;
  border-radius: 4px;
}

.hideHeader :global(.p-datatable-scrollable-header),
.hideHeader :global(.p-datatable-scrollable-footer) {
  display: none;
}

.hideHeader
  :global(table.customDatatable .p-datatable-tbody > tr:last-child > td) {
  border: none;
}

:global(table.customDatatable .p-datatable-thead > tr > th .p-column-title) {
  padding: 0;
  display: inline-flex;
  width: 100%;
}

.hideHeader :global(.p-datatable-thead) {
  display: none;
}

:global(
    .customDatatableContainer.p-datatable .p-sortable-column:focus-visible
  ) {
  outline: var(--Primary-700) auto 1px;
  border-width: 1px;
}

:global(
    .customDatatableContainer.p-datatable
      .p-sortable-column
      .p-sortable-column-icon
  ) {
  width: 12px;
  height: 12px;
}

:global(td span span.cellContentEllipsis) {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* pagination */
:global(.customDatatableContainer.p-datatable .p-paginator-bottom) {
  display: grid;
  padding: 16px 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--header-color-local);
  border-bottom: none;
  justify-items: end;
  gap: 16px;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr auto auto;
}

:global(
    .customDatatableContainer.p-datatable .p-paginator-bottom > .table-results
  ) {
  grid-column: 1 / -1;
  justify-self: end;
}

:global(
    .customDatatableContainer.p-datatable
      .p-paginator-bottom
      > .rowsPerPageDropdown
  ) {
  justify-self: end;
}

:global(
    .customDatatableContainer.p-datatable
      .p-paginator-bottom
      > .previous-page-button
  ) {
  margin-left: 8px;
}

:global(
    .customDatatableContainer.p-datatable
      .p-paginator-bottom
      > .next-page-button
  ) {
  justify-self: end;
}

:global(
    .customDatatableContainer.p-datatable
      .p-paginator-bottom
      .rowsPerPageDropdown
  ) {
  display: flex;
  align-items: center;
  margin-left: auto;
}

#LoggedinPage_siteContent__narAr > div.Layout_column__8Ldp0.Layout_container__1nNA4.LoggedinPage_main__ehZtf > div > div > div.Layout_column__8Ldp0.Layout_container__1nNA4 > div.Layout_column__8Ldp0.Layout_container__1nNA4.Layout_wrapper__3jq8m > div > div > div.Table_tableContainer__2WYn1 > div > div > div > div.p-datatable-scrollable-body > table > tbody > tr.p-row-odd
/* #endregion */
/* #region Custom Filters */
.customFilterTrigger {
  align-self: flex-start;
}

.customFiltersBlock {
  width: 486px;
  padding: 16px;
}

.filterHeader,
.filterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filterRow {
  padding-bottom: 16px;
}

button.addFilterButton {
  padding: 12px 8px;
}

.customFiltersBlock .addFilterButton {
  padding-left: 0;
}

.applyFilterButton {
  margin-top: 16px;
  margin-right: 8px;
}

.filterColumn {
  display: inline-flex;
}

.filterColumn.value {
  width: 196px;
}

.filterColumn.columnName {
  width: 200px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.filterColumn.filter {
  width: 150px;
}

.filterColumn.action {
  flex-direction: column;
  width: 32px;
  padding: 8px 4px 0;
}

.filterColumn.action svg {
  cursor: pointer;
}

:global(.customFilters-overlay) {
  /* disabled overlay */
  background: transparent;
  width: 0;
  height: 0;
  position: static;
}

:global(.customFilters-content) {
  z-index: 999 !important;
  box-shadow: 0 5px 10px rgb(0 0 0 / 7%), 0 3px 6px rgb(0 0 0 / 4%),
    0 2px 4px rgb(0 0 0 / 6%);
  border-radius: 4px;
  padding: 0;
  margin-top: 8px;
  border: 1px solid var(--Grey-200);
  width: fit-content !important;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

:global(.p-column-resizer) {
  margin: 9px 0;
  border-right: 1px solid var(--Grey-200);
  height: calc(100% - 18px);
}

/* #endregion */

:global(.p-datatable .p-datatable-loading-overlay) {
  background-color: transparent;
  min-height: 200px;
}

.rowActionContextMenuBtn {
  position: relative;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
}

:global(.padding-4) {
  padding: 4px;
}

:global(.customDatatableContainer.p-datatable .table-results) {
  margin: 0;
}

@media screen and (min-width: 1024px) {
  :global(.customDatatableContainer.p-datatable .p-paginator-bottom) {
    display: flex;
    padding: 16px 0;
  }

  :global(
      table.customDatatable
        .p-datatable-tbody
        > tr:not(.p-highlight)
        > td.p-selection-column
        .p-checkbox
    ) {
    visibility: hidden;
  }

  :global(
      table.customDatatable .p-datatable-tbody > tr > td.p-selection-column
    ) {
    display: table-cell;
    box-shadow: none;
    overflow: hidden;
  }

  :global(
      table.customDatatable
        .p-datatable-tbody
        > tr
        > td
        div.action-button-wrapper
    ) {
    box-shadow: none;
  }

  :global(table.customDatatable .p-datatable-thead > tr > th .p-column-title) {
    padding: 0 8px;
  }

  .tableActionsCol {
    box-shadow: none;
  }

  .tableCell {
    padding: 2px 8px;
  }

  .tableRowActions {
    justify-content: center;
  }

  /* global because table styles are global */
  :global(.customDatatableRowActionsOnlyOnHover) {
    visibility: hidden;
  }

  :global(
      .customDatatableContainer.p-datatable
        .p-datatable-tbody
        > tr:hover
        .customDatatableRowActionsOnlyOnHover
    ) {
    visibility: visible;
  }
}
