.chipsContainer {
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 8px;
  background-color: var(--Grey-50);
  border: 1px solid #d7dbe3;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 4px;
  flex-shrink: 0;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Grey-900);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  width: 100%;
}

.imageWrapper {
  display: flex;
  align-items: center;
  height: 15px;
  width: 15px;
  background: transparent;
  border: none;
  margin-left: 16px;
  cursor: pointer;
}

:global(.p-chips .p-chips-multiple-container .p-chips-token) {
  padding: 0;
  background: none;
}
