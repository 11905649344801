.container {
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
}

.row {
  composes: container;
  flex-direction: row;
}

.column {
  composes: container;
  flex-direction: column;
}

.spacer {
  display: flex;
}

.grid {
  display: grid;
  grid-template-columns: minmax(250px, 1fr) minmax(250px, 1fr);
  column-gap: 32px;
  row-gap: 32px;
  width: 100%;
  align-items: start;
}

.mobileGrid {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
  width: 100%;
}

:global(.fill-available) {
  width: -webkit-fill-available;
}

:global(.align-self-end) {
  align-self: flex-end;
}
